<template>
	<el-form-item :label="title" :required="true">
		<div class="industriaImgs">
			<div class="industriaImgs-content" v-for="(item, index) in images" :key="index">
				<Upload
					v-model="item.basicInfoImg"
					:modulePath="modulePath"
					operator="zhangl"
					:maxCount="1"
					:disabled="disabled"
					@uploadImg="changeImage($event, images, index)"
				></Upload>
				<p class="industriaImgs-title">图片介绍</p>
				<el-input type="textarea" resize="none" v-model.trim="item.description" placeholder="请输入" :disabled="disabled" autosize></el-input>
				<i class="el-icon-close" @click="deleteBasicImg(item, index)" v-if="!disabled"></i>
			</div>
			<el-button class="uploadContent" icon="el-icon-plus" @click="addData()" v-if="!disabled">上传</el-button>
		</div>
		<span class="el-form-item__error" v-if="showError">图片介绍为必填</span>
	</el-form-item>
</template>

<script>
export default {
	data() {
		return {
			images: [],
			showError: false,
		};
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		imageData: {
			type: Array,
			default() {
				return [];
			},
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		modulePath: {
			type: String,
			default: '',
		},
	},
	watch: {
		imageData: {
			handler(val) {
				if (val.length > 0) {
					console.log(val);
					this.images = JSON.parse(JSON.stringify(val));
				}
			},
			deep: true,
		},
		images: {
			handler(val) {
				if (val.length > 0 && this.required) {
					let flag = val.every((item) => {
						return item.basicInfoImg.length > 0 && item.description;
					});
					this.showError = !flag;
				}
			},
			deep: true,
		},
	},
	methods: {
		// 增加产业详情图
		addData() {
			this.images.push({
				basicInfoImg: [],
				description: '',
			});
		},
		deleteBasicImg(item, index) {
			this.images.splice(index, 1);
			this.$emit('deleteBasicImg', item);
		},
		changeImage(fileList, images, index) {
			this.images[index].basicInfoImg = fileList;
		},
	},
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
	},
};
</script>

<style lang="scss" scoped>
.industriaImgs {
	&-content {
		background-color: #f8f8f8;
		border-radius: 4px;
		margin-bottom: 8px;
		padding: 8px 12px;
		position: relative;
		&:last-child {
			margin-bottom: 0;
		}
		.el-icon-close {
			position: absolute;
			right: 10px;
			top: 8px;
			font-size: 20px;
			cursor: pointer;
		}
	}
	/deep/ .uploadContent {
		margin-left: 0;
		border-color: #c4c4c4;
		color: #333;
	}
}
</style>